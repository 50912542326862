/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "..";
import { edgeService, networkService } from "../../services";
import { AaaServerProfile } from "../../types/AAAProfile";
import { Edge, EdgeWithPool } from "../../types/edge";
import { EdgePool } from "../../types/EdgePool";
import { SNMP } from "../../types/snmp";
import { fillTimeSeriesData } from "~/views/subscribers/graphs/graphHelper";
import { MetricsQuery } from "~/services/APIServices";
import { observeStoreChange } from "../utils";
import { selectCompareEnable } from "./utilitySlice";

type initState = {
  open: boolean;
  current: Edge | EdgeWithPool | null;
  data: Edge[];
  loading: boolean;
  edgePoolsLoading: boolean;
  showErrors: boolean;
  edgePools: EdgePool[];
  edgesWithPool: EdgeWithPool[];
  alarmTrigger: boolean;
  currentEdgePool: EdgePool | null;

  snmp: SNMP[];
  aaaProfiles: AaaServerProfile[];

  support: {
    open: boolean;
    dialog: boolean;
    type: "enable" | "disable" | null;
    id: string | null;
    isRunning: boolean;
  };

  kpiExport: {
    open: boolean;
    operationID: string | null;
    isRunning: boolean;
  };

  metricsLoading: boolean;
  metricsError: string | null;

  metrics: {
    [key: string]: [number, number][];
  };

  compareMetrics: Record<string, [number, number][]>;

  aggregatedMetrics: {
    [key: string]: [number, number][];
  };
  edgeCGWMetricsTable : {
    [key: string]: [number, number][];
  };
};

export const initialState: initState = {
  open: false,
  current: null,
  loading: true,
  edgePoolsLoading: true,
  data: [],
  edgesWithPool: [],
  showErrors: false,
  edgePools: [],
  currentEdgePool: null,
  alarmTrigger: false,

  snmp: [],
  aaaProfiles: [],

  support: {
    open: false,
    dialog: false,
    id: null,
    type: null,
    isRunning: false,
  },

  kpiExport: {
    open: false,
    operationID: null,
    isRunning: false,
  },
  metricsLoading: true,
  metricsError: null,
  metrics: {},
  compareMetrics: {},
  aggregatedMetrics: {},
  edgeCGWMetricsTable:{}
};

export const fetchEdgeMetrics = createAsyncThunk(`edge/fetchEdgeMetrics`, async (query : MetricsQuery, thunkApi)=> {
  try {
    const data = await edgeService.getMetrics(query);
    return data;
  } catch (err : any) {
    const errorMsg = err.errors[0].message;
    return thunkApi.rejectWithValue(errorMsg);
  }
});

export const fetchEdgeMetricsCompare = createAsyncThunk(`edge/fetchEdgeMetricsCompare`, edgeService.getMetrics)
export const fetchEdgeMetricsAggregate = createAsyncThunk(
  `edge/fetchEdgeMetricsAggregate`,
  edgeService.getAggregateMetrics
);

export const fetchEdges = createAsyncThunk("edge/fetchEdges", async ( query?: {timestamp?: number} ) => {
  const timestamp = query?.timestamp;
  try {
    const query = timestamp ? { timestamp } : {};
    const data = await edgeService.getEdges(query);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchEdgePools = createAsyncThunk("edge/fetchEdgePools", async ( query?: {timestamp?: number} ) => {
  const timestamp = query?.timestamp;
  try {
    const queryString = timestamp ? { timestamp } : {};
    const edgePools = await edgeService.getEdgePool(queryString);
    return edgePools;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchSNMP = createAsyncThunk("edge/fetchSNMP", async () => {
  try {
    const result = await networkService.getSNMPData();
    const JsonArray = await result.text();
    const snmpData = JSON.parse(JsonArray).data as SNMP[];
    return snmpData;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const fetchAAAProfiles = createAsyncThunk("edge/fetchAAAProfiles", async () => {
  try {
    const result = await networkService.getAAAServerProfilesData();
    const JsonArray = await result.text();
    const aaaProfiles = JSON.parse(JsonArray).data as AaaServerProfile[];
    return aaaProfiles;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const edgeSlice = createSlice({
  name: "edge",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setShowErrors: (state, action: PayloadAction<boolean>) => {
      state.showErrors = action.payload;
    },
    setValues: (state, action: PayloadAction<Edge | EdgeWithPool | null>) => {
      state.current = action.payload;
    },
    setData: (state, action: PayloadAction<Edge[]>) => {
      state.data = action.payload;
    },

    setSNMP: (state, action: PayloadAction<SNMP[]>) => {
      state.snmp = action.payload;
    },
    setSupportPopUpOpen: (state, action: PayloadAction<boolean>) => {
      state.support.open = action.payload;
    },
    setSupportDialogOpen: (
      state,
      action: {
        payload: {
          open: boolean;
          type: "enable" | "disable" | null;
        };
      }
    ) => {
      state.support.dialog = action.payload.open;
      state.support.type = action.payload.type;
    },
    setSupportId: (state, action: PayloadAction<string | null>) => {
      state.support.id = action.payload;
    },
    setSupportIsRunning: (state, action: PayloadAction<boolean>) => {
      state.support.isRunning = action.payload;
    },
    setSupport(state, action: PayloadAction<{ open: boolean; id: string | null; isRunning: boolean }>) {
      state.support.open = action.payload.open;
      state.support.id = action.payload.id;
      state.support.isRunning = action.payload.isRunning;
    },
    setCurrentPool(state, action: PayloadAction<EdgePool | null>) {
      state.currentEdgePool = action.payload;
    },

    setKPIExportOpen(state, action: PayloadAction<boolean>) {
      state.kpiExport.open = action.payload;
    },
    setKPIExportID(state, action: PayloadAction<string | null>) {
      state.kpiExport.operationID = action.payload;
    },
    setKPIExportRunning(state, action: PayloadAction<boolean>) {
      state.kpiExport.isRunning = action.payload;
    },
    clearCompareMetrics(state) {
      state.compareMetrics = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEdges.fulfilled, (state, action) => {
        state.data = action.payload;
        const { alarmTriggered, updatedEdges } = updateEdgesWithPool(action.payload, state.edgePools);
        state.edgesWithPool = updatedEdges;
        state.alarmTrigger = alarmTriggered;
        state.loading = false;

        // sync the current subscriber with the new data
        if (state.current && !state.open) {
          const current = state.edgesWithPool.find((item) => item.id === state.current?.id);
          if (current) {
            state.current = current;
          } else {
            state.current = null;
          }
        }
      })
      .addCase(fetchEdges.rejected, (state, action) => {
        state.loading = false;
        console.log(action);
      })
      .addCase(fetchEdgePools.fulfilled, (state, action) => {
        state.edgePools = action.payload;
        state.edgePoolsLoading = false;
      })
      .addCase(fetchEdgePools.rejected, (state, action) => {
        console.log(action);
        state.edgePoolsLoading = false;
      })
      .addCase(fetchSNMP.fulfilled, (state, action) => {
        state.snmp = action.payload;
      })
      .addCase(fetchSNMP.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(fetchAAAProfiles.fulfilled, (state, action) => {
        state.aaaProfiles = action.payload;
      })
      .addCase(fetchAAAProfiles.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(fetchEdgeMetrics.pending, (state, action) => {
        if(action.meta.arg.showLoading) state.metricsLoading = true;
      })
      .addCase(fetchEdgeMetrics.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach((obj) => {
          const metricData = obj.metricData?.map((metric) => {
            if (!metric.dataPoints.length) {
              state.edgeCGWMetricsTable[obj.metric] = []
              return [];
            }
            try {
              const lastIndex = metric.dataPoints.length - 1
              state.edgeCGWMetricsTable[obj.metric] = metric.dataPoints[lastIndex]
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: action.meta.arg.interval.startTime,
                endTime: action.meta.arg.interval.endTime,
                interval: action.meta.arg.resolution,
                fillType: "null",
                name: obj.metric,
              });
            } catch (e) {
              console.error("Error in fillTimeSeriesData", e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          
          state.metricsError = null;
          state.metricsLoading = false;
          state.metrics[obj.metric] = metricData ? metricData[0]: [];
        });
      })
      .addCase(fetchEdgeMetricsCompare.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach((obj) => {
          const metricData = obj.metricData.map((metric) => {
            if (!metric.dataPoints.length) return [];
            try {
              return fillTimeSeriesData({
                data: metric.dataPoints,
                startTime: action.meta.arg.interval.startTime,
                endTime: action.meta.arg.interval.endTime,
                interval: action.meta.arg.resolution,
                fillType: "null",
                name: obj.metric,
              });
            } catch (e) {
              console.error("Error in fillTimeSeriesData", e, obj.metric, metric.dataPoints);
              return metric.dataPoints.length ? metric.dataPoints : [];
            }
          });
          state.compareMetrics[obj.metric] = metricData[0];
        });
      })
      .addCase(fetchEdgeMetrics.rejected, (state, action) => {
        state.metricsLoading = false;
        state.metricsError = action.payload as string;
      })
      .addCase(fetchEdgeMetricsAggregate.pending, (state) => {
        state.metricsLoading = true;
      })
      .addCase(fetchEdgeMetricsAggregate.fulfilled, (state, action) => {
        const data = action.payload;
        data.forEach((obj) => {
          if (!obj.metricData.length) {
            state.aggregatedMetrics[obj.metric] = [];
            return [];
          }
          try {
            const metricData = fillTimeSeriesData({
              data: obj.metricData,
              startTime: action.meta.arg.interval.startTime,
              endTime: action.meta.arg.interval.endTime,
              interval: action.meta.arg.resolution,
              fillType: "previous",
            });
            state.aggregatedMetrics[obj.metric] = metricData;
          } catch (e) {
            console.error("Error in fillTimeSeries", e, obj.metric, obj.metricData);
            const metricData = obj.metricData.length ? obj.metricData : [];
            state.aggregatedMetrics[obj.metric] = metricData;
          }
          state.metricsLoading = false;
        });
      })
      .addCase(fetchEdgeMetricsAggregate.rejected, (state, action) => {
        console.error(action.error);
        state.metricsLoading = false;
        throw action.error;
      });
  },
});

export const edgeActions = edgeSlice.actions;
export default edgeSlice.reducer;

export const edgeOpen = (state: RootState) => state.edge.open;
export const showErrors = (state: RootState) => state.edge.showErrors;
export const edgeState = (state: RootState) => state.edge.current;
export const edgeLoading = (state: RootState) => state.edge.loading;

export const edgeData = (state: RootState) => state.edge.data;
export const edgePools = (state: RootState) => state.edge.edgePools;
export const edgePoolsLoading = (state: RootState) => state.edge.edgePoolsLoading;
export const edgeCurrentPool = (state: RootState) => state.edge.currentEdgePool;
export const edgeEdgesWithPool = (state: RootState) => state.edge.edgesWithPool;
export const edgeAlarmTrigger = (state: RootState) => state.edge.alarmTrigger;

export const SNMP_DATA = (state: RootState) => state.edge.snmp;
export const AAAProfiles_DATA = (state: RootState) => state.edge.aaaProfiles;

export const edgeSupportPopUpOpen = (state: RootState) => state.edge.support.open;
export const edgeSupportDisableDialogOpen = (state: RootState) =>
  state.edge.support.dialog && state.edge.support.type === "disable";
export const edgeSupportEnableDialogOpen = (state: RootState) =>
  state.edge.support.dialog && state.edge.support.type === "enable";
export const edgeSupportType = (state: RootState) => state.edge.support.type;
export const edgeSupportId = (state: RootState) => state.edge.support.id;
export const edgeSupportIsRunning = (state: RootState) => state.edge.support.isRunning;

export const edgeMetricLoading = (state: RootState) => state.edge.metricsLoading;
export const edgeMetricsError = (state: RootState) => state.edge.metricsError;
export const edgeMetrics = (state: RootState) => state.edge.metrics;
export const edgeMetric = (metric: string) => (state: RootState) => state.edge.metrics[metric];
export const edgeCompareMetrics = (state: RootState) => state.edge.compareMetrics;
export const edgeCGWMetricsTableData = (state: RootState) => state.edge.edgeCGWMetricsTable;

export const edgeAggregatedMetric = (metric: string) => (state: RootState) => state.edge.aggregatedMetrics[metric];
export const has5gEdge = (state: RootState) => state.edge.data.some((edge) => edge.networkType === "5G");

function updateEdgesWithPool(edges: Edge[], pools: EdgePool[]) {
  // alarm trigger flag
  let alarmTriggered = false;

  const updatedEdges = edges.map((item, i) => {
    const edge: EdgeWithPool = item;
    // check if alarm is not triggered and if previous edge and edge network mode is not the same then trigger alarm
    if (!alarmTriggered && i > 0 && edges[i - 1].networkMode !== edge.networkMode) {
      alarmTriggered = true;
    }

    // get pool for edge
    const pool = pools.find((pool) => pool.edgeList.find((edgeList) => edgeList.id === edge.id));

    // if pool is found then update edge with pool info
    if (pool) {
      edge.edgeGroupId = pool.edgeGroupId;
      edge.edgePoolname = pool.name;
      edge.edgePoolId = pool.id;
      edge.edgeRole = pool.edgeList.find((edgeList) => edgeList.id === edge.id)?.role;
    } else {
      edge.edgePoolname = "Standalone Edges";
      edge.edgePoolId = undefined;
      edge.edgeRole = "Standalone";
    }
    return edge;
  });

  // handle pools with no edges
  pools.forEach((pool) => {
    if (!updatedEdges.find((edge) => edge.edgePoolname === pool.name)) {
      // add empty object to updatedEdges
      updatedEdges.push({
        edgePoolname: pool.name,
        emptyObj: true,
      });
    }
  });

  return {
    updatedEdges,
    alarmTriggered,
  };
}


// clear compare metrics when the compare is closed
setTimeout(() => {
  observeStoreChange(selectCompareEnable, (state) => {
    if (!state) {
      store.dispatch(edgeActions.clearCompareMetrics())
    }
  })
});